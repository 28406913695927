<template>
  <footer class="bg-ansBlack flex flex-col-reverse lg:flex-row lg:justify-between py-12 px-4 lg:px-32 font-source">
    <div class="text-white text-base lg:text-xl lg:text-left w-full lg:w-[355px]" >
      <p class="text-center lg:text-left">
       We want to acknowledge contributions of Abuja residents in fulfilment of their civic responsibilities through payment of taxes.
      </p>

      <p class="text-cadet mt-6 text-center lg:text-left">
        © {{ year }} - {{APP_NAME}}. All rights reserved.
      </p>
    </div>
<div>

    <div class="flex gap-5 justify-center mb-6 text-sm lg:text-xl">
      <div class="text-white font-source font-semibold mb-8 lg:mb-0 text-center"  >
        <p class="mb-3">Track your transaction?</p>
        <router-link
          :to="{ name: 'Track' }"
          class="bg-ansGreen rounded py-3 px-10"
        >
          Track
        </router-link>
      </div>

      <div v-if="!$store.state.general.loggedIn" class="text-white font-source font-semibold mb-8 lg:mb-0 text-center" >
        <p class="mb-3">Are you an Agent or Admin?</p>
        <router-link
          :to="{ name: 'Login' }"
          class="bg-ansGreen rounded py-3 px-10"
        >
          Login
        </router-link>
      </div>
    </div>
    <div>
    <p class="text-[#DCF2ED] text-center mt-5">
    Design & Developed by <img src="@/assets/images/powered_by.png" alt="ANS Logo" class="h-11 inline" />
    </p>
     </div>
</div>
  </footer>
</template>

<script>
export default {
  name: "Footer",

  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped></style>
