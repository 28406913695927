var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('Header'),_c('section',{staticClass:"mt-14 lg:mt-20 h-99 lg:h-h44 w-full pl-6 pr-2 lg:pl-32 flex flex-col justify-center items-start font-source text-white",style:({
      '--bgImage':
        'url(' +
        require('@/assets/images/banners/' + _vm.PROJECT_NAME + '.png') +
        ')',
    }),attrs:{"id":"banner"}},[_vm._m(0),_c('p',{staticClass:"text-sm lg:text-2xl mt-4"},[_vm._v(" Pay your revenue online, All with the click of a button. ")]),_c('button',{staticClass:"bg-white text-ansGreen px-6 py-4 font-semibold mt-12 rounded text-base lg:text-2xl",on:{"click":_vm.showRevForm}},[_vm._v(" I want to make revenue payment ")])]),_c('section',{staticClass:"bg-white pt-10 lg:pt-28 px-4 lg:px-32 font-source"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"flex justify-center pb-22"},[_c('button',{staticClass:"text-ansGreen font-semibold bg-ansLemon py-4 lg:py-5 px-5 lg:px-10 rounded text-base lg:text-2xl",on:{"click":_vm.showRevForm}},[_vm._v(" I want to make revenue payment ")])])]),_c('Footer'),(_vm.$store.state.general.showForm)?_c('Modal',{attrs:{"value":_vm.showForm,"size":_vm.modalSize,"mt":"7"}},[(_vm.$store.state.general.step <= 5)?_c('GenerateInvoice',{on:{"doned":_vm.generateDone}}):_vm._e(),(_vm.$store.state.general.step == 6)?_c('Invoice',{attrs:{"invoiceNumber":this.$store.getters['general/ind'].invoiceNumber}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"text-3pxl lg:text-6xl font-light"},[_vm._v(" Pay your revenue so"),_c('br'),_vm._v(" we "),_c('span',{staticClass:"font-bold",staticStyle:{"text-shadow":"4px 1px #155135"}},[_vm._v(" can grow together! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between"},[_c('div',[_c('h3',{staticClass:"text-ansBlack text-2xl lg:text-4xl font-semibold"},[_vm._v(" How to pay your tax ")]),_c('h5',{staticClass:"text-cadet text-base lg:text-2xl mt-1.5"},[_vm._v(" You can do so easily with three simple steps ")])]),_c('div',{staticClass:"hidden lg:block"},[_c('img',{attrs:{"src":require("@/assets/images/icons/tax_process.svg"),"alt":"icons"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between gap-7 lg:gap-40 mt-11 mb-16 lg:my-32"},[_c('div',{staticClass:"max-w-xs"},[_c('img',{staticClass:"h-6 w-6 lg:h-10 lg:w-10",attrs:{"src":require("@/assets/images/icons/form.svg"),"alt":"icons"}}),_c('h4',{staticClass:"text-ansBlack mt-3 text-base"},[_vm._v("Provide details")]),_c('p',{staticClass:"text-cadet mt-2 text-sm"},[_vm._v(" Provide your phone number, NIN, email and address ")])]),_c('div',{staticClass:"max-w-2xs"},[_c('img',{staticClass:"h-6 w-6 lg:h-10 lg:w-10",attrs:{"src":require("@/assets/images/icons/mouse.svg"),"alt":"icons"}}),_c('h4',{staticClass:"text-ansBlack mt-3 text-base"},[_vm._v("Select tax")]),_c('p',{staticClass:"text-cadet mt-2 text-sm"},[_vm._v(" Select the tax you would like to pay for. ")])]),_c('div',{staticClass:"max-w-xs"},[_c('img',{staticClass:"h-6 w-6 lg:h-10 lg:w-10",attrs:{"src":require("@/assets/images/icons/invoice.svg"),"alt":"icons"}}),_c('h4',{staticClass:"text-ansBlack mt-3 text-base"},[_vm._v("Generate invoice")]),_c('p',{staticClass:"text-cadet mt-2 text-sm"},[_vm._v(" An invoice will be generated for your to make payment with ")])])])
}]

export { render, staticRenderFns }